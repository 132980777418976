import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, LinkBox, Link, Section } from "@quarkly/widgets";
import { MdPhone } from "react-icons/md";
const defaultProps = {
	"padding": "90px 0 50px 0",
	"background": "--color-darkL1",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "50px 30px 50px 30px",
			"background": "linear-gradient(180deg,rgba(8, 4, 4, 0.71) 0.5%,rgba(11, 10, 10, 0.62) 98.1%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-1.jpg?v=2024-07-11T10:42:28.074Z) 50% 60%/auto repeat scroll padding-box",
			"display": "flex",
			"justify-content": "space-around",
			"align-items": "center",
			"margin": "0px 0px 80px 0px",
			"md-flex-direction": "column",
			"md-align-items": "stretch",
			"md-margin": "0px 0px 50px 0px",
			"md-padding": "30px 30px 30px 30px",
			"sm-padding": "20px 15px 20px 15px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "60%",
			"md-margin": "0px 0px 30px 0px",
			"md-width": "100%",
			"sm-margin": "0px 0px 18px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 400 42px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 12px 0px",
			"children": "Besuchen Sie uns noch heute!"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "normal 300 20px/1.5 --fontFamily-sans",
			"children": "Wir laden Sie ein, La Dolce Pizza zu besuchen und unser köstliches Menü selbst zu erleben. Egal, ob Sie vor Ort essen oder etwas mitnehmen, wir versprechen Ihnen eine Mahlzeit, die Ihre Sinne erfreut und Lust auf mehr macht."
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"padding": "15px 25px 15px 25px",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-light",
			"background": "rgba(247, 251, 255, 0.12)",
			"sm-padding": "10px 15px 10px 15px",
			"href": "tel:+49302823965"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdPhone,
			"size": "24px",
			"color": "--light",
			"margin": "0px 8px 0px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--light",
			"children": "+49302823965"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 80px 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Adresse"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "Schiffbauerdamm 8, 10117 Berlin, Germany"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Reservierung"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+49302823965",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"children": "+49302823965"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Öffnungszeit"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "Mo bis Fr (10 – 18 Uhr)"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"color": "#d7c7c5",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"children": "© 2024 La Dolce Pizza. All rights reserved."
		}
	}
};

const Footer23 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<LinkBox {...override("linkBox")}>
				<Icon {...override("icon")} />
				<Text {...override("text2")} />
			</LinkBox>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text5")} />
				<Link {...override("link")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
		</Box>
		<Text {...override("text8")} />
		{children}
	</Section>;
};

Object.assign(Footer23, { ...Section,
	defaultProps,
	overrides
});
export default Footer23;